// @file intersection observer

type CallbackFunctionType = (element: HTMLElement) => void

const canObserveIntersection: boolean = 'IntersectionObserver' in window

function isIntersecting(entry: IntersectionObserverEntry): boolean {
  // Most browsers support isIntersecting, fall back to intersectionRatio > 0 for things like Edge 15 and Samsung Internet 5 (they are both released in 2017)
  // Reference: https://github.com/w3c/IntersectionObserver/issues/211
  return (
    ('isIntersecting' in IntersectionObserverEntry.prototype && entry.isIntersecting) || entry.intersectionRatio > 0
  )
}

/**
 * @deprecated please use observeElementIntersection instead, or the vue composable useViewportIntersection
 * Observe the visibility of a dom element and call a function when visibile.
 * Returns false if intersection observer is not supported by the browser.
 * @param element The dom element to observe the intersection of
 * @param callback The method to call
 */
function observeIntersection(element: HTMLElement, callback: CallbackFunctionType): boolean {
  if (canObserveIntersection) {
    const intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (isIntersecting(entry)) {
          const target = entry.target
          observer.unobserve(target)
          callback(element)
        }
      })
    })
    intersectionObserver.observe(element)
    return true
  }
  return false
}

/**
 * Observe the visibility of a dom element and call a function when visibile.
 * Returns false if intersection observer is not supported by the browser.
 * @param element The dom element to observe the intersection of
 * @param callback The method to call
 */
const observeElementIntersection = (
  element: HTMLElement,
  callback: CallbackFunctionType,
  options = {},
): IntersectionObserver => {
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (isIntersecting(entry)) {
        const target = entry.target
        observer.unobserve(target)
        callback(element)
      }
    })
  }, options)
  intersectionObserver.observe(element)

  return intersectionObserver
}

export { canObserveIntersection, observeElementIntersection, observeIntersection }
