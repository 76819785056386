/**
 * @file helper for styles to make overflow + border radius + transform work
 * Reference: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
 * -webkit-mask-image: -webkit-radial-gradient(white, black); works on all browsers but the performance is not good on Google Chrome
 * => use it for Safari and the below for other browsers (these work on browsers that's not than Safari):
 * -webkit-backface-visibility: hidden;
 * -moz-backface-visibility: hidden;
 * -webkit-transform: translate3d(0, 0, 0);
 * -moz-transform: translate3d(0, 0, 0);
 */

function overflowBorderRadiusTransformFixStyle(isSafari: boolean): Record<string, string> {
  return isSafari
    ? { '-webkit-mask-image': '-webkit-radial-gradient(white, black)' }
    : {
        '-webkit-backface-visibility': 'hidden',
        '-moz-backface-visibility': 'hidden',
        '-webkit-transform': 'translate3d(0, 0, 0)',
        '-moz-transform': 'translate3d(0, 0, 0)',
      }
}

export default overflowBorderRadiusTransformFixStyle
