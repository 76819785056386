<template>
  <img
    :fetchPriority="fetchPriority"
    :alt="alt"
    :data-testid="dataTestid"
    :loading="loading"
    :class="{ 'beethoven-success': success, 'beethoven-fail': !success && failed }"
    :src="computedSrc"
    :width="computedWidth"
    :height="computedHeight"
    :style="style"
    @load="onLoad"
    @error="onError"
  />
</template>

<script>
import { BLANK_IMAGE } from '@@/bits/attachments'
import { browserCan } from '@@/bits/browser'
import device from '@@/bits/device'
import { canObserveIntersection, observeIntersection } from '@@/bits/intersection'
const canNativelyLazyLoad = browserCan('lazyLoad')

export default {
  props: {
    alt: {
      type: String,
      default: '',
    },
    dataTestid: String,
    src: {
      type: String,
      default: BLANK_IMAGE,
    },
    backgroundColor: String,
    width: Number,
    height: Number,
    postWidth: Number,
    postHeight: Number,
    loading: {
      type: String,
      default: 'auto',
      validator(value) {
        return ['auto', 'eager', 'lazy'].includes(value)
      },
    },
    fetchPriority: {
      type: String,
      default: 'auto',
      validator(value) {
        return ['auto', 'low', 'high'].includes(value)
      },
    },
  },
  data() {
    return {
      success: false,
      failed: false,
      isVisible: !canObserveIntersection,
    }
  },
  computed: {
    computedSrc() {
      // Browser can natively lazy load. No need to do anything special.
      if (canNativelyLazyLoad) return this.src
      // Image is visible now. Send the real src.
      if (this.loading !== 'lazy' || this.isVisible) return this.src
      // Image is invisible. Use blank.
      return BLANK_IMAGE
    },
    computedWidth() {
      return this.postWidth || this.width
    },
    computedHeight() {
      return this.postHeight || this.height
    },
    style() {
      // Since Safari 15 a border is included by default around lazy loaded images, and the following hack is a revised version of a solution online
      // as found here: https://stackoverflow.com/questions/71992334/how-to-remove-white-border-on-lazy-loaded-images-in-safari
      // and we only apply the hack when the image has not finished loading
      const hideSafariBorderStyle =
        this.loading === 'lazy' && device?.safari && !this.success ? { 'clip-path': 'inset(1px)' } : {}
      return {
        ...hideSafariBorderStyle,
        'background-color': this.success ? '' : this.backgroundColor,
        width: this.computedWidth + 'px',
        height: this.computedHeight + 'px',
      }
    },
  },
  mounted() {
    if (!canNativelyLazyLoad) {
      if (this.loading === 'lazy' && !this.isVisible) {
        observeIntersection(this.$el, () => {
          this.isVisible = true
        })
      }
    }
  },
  methods: {
    triggerCustomEvent(element) {
      const event = new CustomEvent('Event', { bubbles: false, cancelable: true })
      element.dispatchEvent(event)
    },
    onLoad() {
      if (this.computedSrc !== BLANK_IMAGE) {
        this.success = true
        this.$emit('load')
        const beethovenLoadEvent = new Event('beethoven-load', {
          bubbles: true,
        })
        this.$el.dispatchEvent(beethovenLoadEvent)
      }
    },
    onError(e) {
      if (this.computedSrc !== BLANK_IMAGE) {
        this.failed = true
        const error = new Error(`Failed to load image. Src: ${this.src}`)
        error.event = e
        this.$emit('error', error)
        const beethovenErrorEvent = new Event('beethoven-error', {
          bubbles: true,
        })
        this.$el.dispatchEvent(beethovenErrorEvent)
      }
    },
  },
}
</script>
