<script lang="ts" setup>
import { attachmentLoadingAnimationColorTailwindClass } from '@@/bits/attachments'
import device from '@@/bits/device'
import overflowBorderRadiusTransformFixStyle from '@@/bits/overflow_border_radius_transform_fix'
import { postColorValues } from '@@/bits/post_color'
import BluePurpleLanterns from '@@/images/blue_purple_lanterns.png'
import type { PostColor } from '@@/types'
import BaseImage from '@@/vuecomponents/base_image.vue'
import { processedUrl, proxiedUrl } from '@padlet/vivaldi-client'
import { sample } from 'es-toolkit'
import { computed, ref, withDefaults } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * URL for image.
     */
    src: string
    /**
     * Original image width.
     */
    originalImageWidth: number
    /**
     * Original image height.
     */
    originalImageHeight: number
    /**
     * Resulting component width.
     */
    width?: number
    /**
     * Resulting component height. Ignored for calculation.
     * Only use to send to Vivaldi if processImage is true.
     */
    height?: number
    /**
     * Resize strategy. Ignored if no aspect ratio is provided.
     * @values crop, stretch, fit.
     */
    resizeStrategy?: 'crop' | 'stretch' | 'fit'
    /**
     * If specified, thumbnail height will be computed based on this value.
     * Value should be `width / height`, i.e. `2` will be wide and `0.5` will be tall.
     */
    aspectRatio?: number
    /**
     * Alt text for the image.
     */
    alt?: string
    /**
     * Title to display when the image fails to load.
     * This is used as the image alt text if no alt prop is given.
     */
    title?: string
    /**
     * Determines if we should load the image lazily.
     */
    lazyLoading?: boolean
    /**
     * Determines whether image should be proxied to vivaldi for caching.
     */
    proxyImage?: boolean
    /**
     * Determines whether image should be processed before loading.
     */
    processImage?: boolean
    /**
     * Placeholder color for the thumbnail.
     * @values red, blue, green, yellow, purple, pink, cyan, orange, transparent, random.
     */
    placeholderColor?: string
    /**
     * Placeholder 'background-color' value.
     */
    placeholderColorValue?: string
    /**
     * If true, display placeholder image when original image fails to load
     * Otherwise, show image title on top of placeholder solid background.
     */
    fallbackToPlaceholder?: boolean
    /**
     * If passed, will enable animation for the placeholder.
     */
    placeholderAnimationVariant?: 'dark' | 'light'
    /**
     * data-testid.
     */
    dataTestid?: string
    /**
     * Flip the image.
     */
    flipDirection?: 'horizontal' | 'vertical'
    /**
     * Determines where the image position is when used in conjunction with object-fit
     * Otherwise the image will always be cropped from the center.
     */
    objectPosition?: string
    /**
     * Fetch priority for image
     */
    fetchPriority?: 'auto' | 'high' | 'low'
    /**
     * Determines whether to add the `pointer-events-none` class to disable the image drag event.
     */
    disablePointerEvents?: boolean
  }>(),
  {
    width: undefined,
    height: undefined,
    resizeStrategy: 'crop',
    aspectRatio: undefined,
    alt: undefined,
    title: '',
    lazyLoading: true,
    proxyImage: false,
    processImage: false,
    placeholderColor: 'transparent',
    placeholderColorValue: undefined,
    fallbackToPlaceholder: false,
    placeholderAnimationVariant: undefined,
    dataTestid: undefined,
    flipDirection: undefined,
    objectPosition: undefined,
    fetchPriority: 'auto',
    disablePointerEvents: true,
  },
)

const emit = defineEmits<{
  (e: 'load'): void
  (e: 'error', error: Error): void
}>()

const loaded = ref(false)
const error = ref(false)

const errorPlaceholderImage = BluePurpleLanterns

const onLoad = (): void => {
  loaded.value = true
  emit('load')
}

const onError = (e: Error): void => {
  error.value = true
  emit('error', e)
}

const overflowBorderRadiusStyle = computed(() => overflowBorderRadiusTransformFixStyle(device.safari))

const bgColorClass = computed(() => {
  if (props.placeholderColorValue) return ''

  if (loaded.value) return ''

  if (
    props.placeholderAnimationVariant &&
    postColorValues.includes(props.placeholderColor === 'default' ? null : (props.placeholderColor as PostColor))
  ) {
    return attachmentLoadingAnimationColorTailwindClass({
      postColor: props.placeholderColor as PostColor,
      isLightColorScheme: props.placeholderAnimationVariant === 'light',
    })
  }

  const colorClassMap = {
    red: 'bg-bgi-red',
    blue: 'bg-bgi-blue',
    green: 'bg-bgi-green',
    yellow: 'bg-bgi-yellow',
    purple: 'bg-bgi-purple',
    pink: 'bg-bgi-pink',
    cyan: 'bg-bgi-cyan',
    orange: 'bg-bgi-orange',
    grey: 'bg-highway-200',
  }

  if (props.placeholderColor === 'random') {
    return sample(Object.values(colorClassMap)) ?? ''
  } else {
    return colorClassMap[props.placeholderColor] || ''
  }
})

const url = computed(() => {
  if (props.processImage) {
    const processingOptions: { width: number; height?: number; ar?: number } = { width: props.width as number }
    if (props.height) processingOptions.height = props.height
    if (props.resizeStrategy === 'stretch' && props.aspectRatio) processingOptions.ar = props.aspectRatio
    return processedUrl(props.src, processingOptions)
  }
  return props.proxyImage ? proxiedUrl(props.src) : props.src
})

const originalImageAspectRatio = computed(() => props.originalImageWidth / props.originalImageHeight)

const dimensions = computed(() => {
  // Width is fixed except when resizeStrategy === 'fit' (CASE 3)
  let cropFrameWidth = props.width || 0
  let unit: 'px' | '%' = 'px'

  // These are decided based on the resizing strategy.
  let cropFrameHeight: number
  let cropFrameAspectRatio: number
  let imageElementHeight: number
  let imageElementWidth: number

  // CASE 1: Crop to fit aspect ratio
  if (props.resizeStrategy === 'crop' && props.aspectRatio) {
    // Try to fit as much of the image in the crop frame as possible.
    cropFrameAspectRatio = props.aspectRatio

    // All cropFrameHeight calculation in this method should use Math.ceil.
    // Reason:
    // Browsers can display HTML elements with fractional height.
    // In Vivaldi, when calculating height using width and aspect ratio, we use
    // Math.ceil (see [1] and [2]). So the resulting image height will never be
    // fractional. But the <div> in this component can have fractional height.
    // -> By using Math.ceil, we ensure the <div> will have the same height as
    // the image.
    //
    // [1]: https://github.com/padlet/mozart/blob/21a2867e9c2abf5e71db0bb6c3eaa6b86b6cd904/services/vivaldi/src/vips/fill.js#L30
    // [2]: https://github.com/padlet/mozart/blob/21a2867e9c2abf5e71db0bb6c3eaa6b86b6cd904/services/vivaldi/src/vips/limit_fill.js#L39
    cropFrameHeight = Math.ceil(cropFrameWidth / cropFrameAspectRatio)
    const originalHasWiderAspectRatio = originalImageAspectRatio.value > props.aspectRatio
    if (originalHasWiderAspectRatio) {
      imageElementHeight = cropFrameHeight
      imageElementWidth = originalImageAspectRatio.value * cropFrameHeight
    } else {
      imageElementWidth = cropFrameWidth
      imageElementHeight = imageElementWidth / originalImageAspectRatio.value
    }

    // CASE 2: Stretch to fit aspect ratio
  } else if (props.resizeStrategy === 'stretch' && props.aspectRatio) {
    // Image element and crop frame both have the same dimensions.
    cropFrameAspectRatio = props.aspectRatio
    cropFrameHeight = Math.ceil(cropFrameWidth / cropFrameAspectRatio)
    imageElementWidth = cropFrameWidth
    imageElementHeight = cropFrameHeight

    // CASE 3: Fit parent's dimensions
  } else if (props.resizeStrategy === 'fit') {
    // Fit the image to its parent element regardless of aspect ratio.
    cropFrameAspectRatio = originalImageAspectRatio.value
    cropFrameWidth = 100
    cropFrameHeight = 100
    imageElementWidth = 100
    imageElementHeight = 100
    unit = '%'

    // CASE 4: Scale, keeping original aspect ratio
  } else {
    // Maintain the same ratio as the original, but scale down proportionately if need be.
    cropFrameAspectRatio = originalImageAspectRatio.value
    cropFrameHeight = Math.ceil(cropFrameWidth / cropFrameAspectRatio)
    imageElementWidth = cropFrameWidth
    imageElementHeight = cropFrameHeight
  }

  return {
    cropFrameWidth,
    cropFrameWidthString: `${cropFrameWidth}${unit}`,
    cropFrameHeight,
    cropFrameHeightString: `${cropFrameHeight}${unit}`,
    cropFrameAspectRatio,
    imageElementWidth,
    imageElementWidthString: `${imageElementWidth}${unit}`,
    imageElementHeight,
    imageElementHeightString: `${imageElementHeight}${unit}`,
  }
})
</script>

<template>
  <div
    :class="[
      // ResizableMixin
      'image-thumbnail-crop-frame',
      // Crop
      'overflow-hidden',
      // Center
      'flex',
      'items-center',
      'justify-center',
      bgColorClass,
      disablePointerEvents && 'pointer-events-none',
    ]"
    :style="{
      ...overflowBorderRadiusStyle,
      width: dimensions.cropFrameWidthString,
      height: dimensions.cropFrameHeightString,
      'background-color': placeholderColorValue,
    }"
    :data-aspect-ratio="dimensions.cropFrameAspectRatio"
  >
    <base-image
      v-if="!error"
      :src="url"
      :class="[
        // ResizableMixin
        'image-thumbnail-image',
        flipDirection === 'horizontal' && 'scale-x-[-1]',
        flipDirection === 'vertical' && 'scale-y-[-1]',
      ]"
      :style="
        resizeStrategy === 'fit'
          ? {
              width: dimensions.imageElementWidthString,
              height: dimensions.imageElementHeightString,
              objectFit: 'cover',
              objectPosition: objectPosition,
            }
          : {}
      "
      :alt="alt != undefined ? alt : title"
      :data-testid="dataTestid"
      :height="dimensions.imageElementHeight"
      :width="dimensions.imageElementWidth"
      :loading="lazyLoading ? 'lazy' : 'eager'"
      :fetch-priority="fetchPriority"
      @load="onLoad"
      @error="onError"
    ></base-image>
    <base-image
      v-else-if="fallbackToPlaceholder"
      :src="errorPlaceholderImage"
      :class="[
        // ResizableMixin
        'image-thumbnail-image',
      ]"
      :style="
        resizeStrategy === 'fit'
          ? {
              width: dimensions.imageElementWidthString,
              height: dimensions.imageElementHeightString,
              objectFit: 'cover',
            }
          : {}
      "
      :alt="alt != undefined ? alt : title"
      :data-testid="dataTestid"
      :height="dimensions.imageElementHeight"
      :width="dimensions.imageElementWidth"
      :loading="lazyLoading ? 'lazy' : 'eager'"
      :fetch-priority="fetchPriority"
    />
    <p v-else>{{ title }}</p>
  </div>
</template>
